import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useAxios from "../../hooks/useAxios";
import { useQuery } from "react-query";
import Badge from "@mui/material/Badge";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import EditUser from "../../components/user/EditUser";
import { useState } from "react";
import DeleteConfirm from "../../components/user/DeleteConfirm";

const Users = () => {
  const navigate = useNavigate();
  const api = useAxios();
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [delConfirmOpen, setDelConfirmOpen] = useState(false);
  const [delUserId, setDelUserId] = useState(null);

  const { data } = useQuery("users", async () => {
    const response = await api.get("/api/users");
    return response.data;
  });

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "role",
      headerName: "Role",
      width: 250,
      align: "center",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => {
        let color;
        if (params.value === "Admin") {
          color = "warning";
        } else if (params.value === "Supervisor") {
          color = "success";
        } else {
          color = "primary";
        }
        return <Badge badgeContent={params.value} color={color} />;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "center",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", gap: 2, paddingTop: "10px" }}>
            <BorderColorIcon
              sx={{ color: "secondary.yellow", cursor: "pointer" }}
              onClick={() => editUser(params.id)}
            />
            <DeleteIcon
              sx={{ color: "secondary.red", cursor: "pointer" }}
              onClick={() => deleteUser(params.id)}
            />
          </Box>
        );
      },
    },
  ];

  const deleteUser = (id) => {
    setDelUserId(id);
    setDelConfirmOpen(true);
  };

  const editUser = (id) => {
    setEditUserId(id);
    setEditUserDialogOpen(true);
  };

  const editUserDialogClose = () => setEditUserDialogOpen(false);
  const delUserDialogClose = () => setDelConfirmOpen(false);

  return (
    <>
      <Box sx={{ marginBottom: "1rem" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/user/add")}
        >
          <PersonAddAltIcon />
          <Typography
            sx={{ fontSize: "", fontWeight: "bold", paddingLeft: "5px" }}
          >
            Add User
          </Typography>
        </Button>
      </Box>
      <Box sx={{ width: "100%", height: "680" }}>
        <DataGrid
          rows={data ?? []}
          columns={columns}
          disableRowSelectionOnClick
        ></DataGrid>
      </Box>

      {editUserDialogOpen && (
        <EditUser
          open={editUserDialogOpen}
          handleClose={editUserDialogClose}
          id={editUserId}
        />
      )}

      {delConfirmOpen && <DeleteConfirm open={delConfirmOpen} id={delUserId} handleClose={delUserDialogClose} />}
    </>
  );
};

export default Users;
