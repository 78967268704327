import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";
import * as XLSX from "xlsx";
import useAxios from "../../hooks/useAxios";
import { useQueryClient } from "react-query";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadFile = () => {
  const api = useAxios();
  const [file, setFile] = useState(null);
  const openAlert = useSetRecoilState(withAlert);
  const queryClient = useQueryClient();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);

      const validTypes = ["text/csv"];
      if (!validTypes.includes(file.type)) {
        openAlert({ detail: "Please upload a valid CSV file." });
        return;
      }

      if (file.size > 20 * 1024 * 1024) {
        // 20 MB limit
        openAlert({ detail: "File size should be less than 20 MB." });
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        const header = Object.keys(json[0]);
        const isMatchHeader = matchHeader(header);

        const checkRowDatas = checkRow(json);

        if (checkRowDatas) {
          const formData = new FormData();
          formData.append("file", file);
          const response = await api.post("/api/archived/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(response.data);
          queryClient.invalidateQueries("archived-list");
          return response;
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const matchHeader = (headers) => {
    const requiredHeaders = [
      "Wallet_Type/Wallet_Name",
      "Sender_Name",
      "Receiver_Name",
      "Receiver_NRC",
      "Receiver_Wallet Number",
      "Transferred_Amount_in_MMK",
    ];

    if (headers.length !== requiredHeaders.length) {
      openAlert({ detail: "Invalid headers in CSV file." });
      return false;
    }
    for (let i = 0; i < headers.length; i++) {
      if (headers[i] !== requiredHeaders[i]) {
        openAlert({ detail: "Invalid headers in CSV file." });
        return false;
      }
    }
    return true;
  };

  const checkRow = (rows) => {
    let result = true;
    const invalidRoww = [];

    for (let i = 0; i < rows.length; i++) {
      const element = rows[i];
      const phoneNum = element["Receiver_Wallet Number"].toString();
      const firstThreeDigits = phoneNum.substring(0, 3);
      const columnCount = Object.keys(element).length;

      if (
        columnCount !== 6 ||
        firstThreeDigits !== "959" ||
        phoneNum.length > 12
      ) {
        invalidRoww.push(i + 2);
      }
    }

    if (invalidRoww.length !== 0) {
      openAlert({ detail: `Rows ${invalidRoww} contain invalid data.` });
      result = false;
    }
    return result;
  };

  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
    >
      <Typography>File</Typography>
      <VisuallyHiddenInput
        type="file"
        onChange={handleFileChange}
        accept=".csv"
      />
    </Button>
  );
};

export default UploadFile;
