import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import useAxios from "../../hooks/useAxios";
import { useRecoilValue } from "recoil";
import { role } from "../../recoil/auth";
import { useState, useEffect } from "react";
import { useCurrentAuth } from "../../utilities/useCurrentAuth";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const AssignAgent = ({ accountData, handleAssign }) => {
  const theme = useTheme();
  const api = useAxios();
  const queryClient = useQueryClient();
  const userRole = useRecoilValue(role);
  const currentAuth = useCurrentAuth();
  const [personName, setPersonName] = useState("");
  const [disableSelect, setDisableSelect] = useState(true);

  useEffect(() => {
    if (userRole === "Supervisor" && accountData.length) {
      setDisableSelect(false);
    } else {
      setDisableSelect(true);
    }
  }, [userRole, accountData]);

  const { data: agentList } = useQuery("agent-list", async () => {
    const response = await api.get("api/users/agent");
    return response.data ? response.data : [];
  });

  const handleChange = async (event) => {
    const parts = event.target.value.split("|");
    const name = parts[0];
    const id = Number(parts[1]);
    setPersonName(event.target.value);

    const data = {
      archived_id: accountData,
      assign_to: id,
      assign_by: currentAuth.id,
    };
    const response = await api.post("api/assign-agent", data);
    if (response.status === 201) {
      setDisableSelect(true);
      handleAssign();
      queryClient.invalidateQueries("archived-list");
    }
  };

  return (
    <Box>
      <FormControl sx={{ width: 300 }}>
        <InputLabel id="demo-name-label">Select Agent</InputLabel>
        <Select
          labelId="demo-name-label"
          id="demo-name"
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
          size="small"
          disabled={disableSelect}
        >
          {agentList &&
            agentList.map((data) => (
              <MenuItem
                key={data.id}
                value={`${data.name}|${data.id}`}
                style={getStyles(data.name, personName, theme)}
              >
                {data.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default AssignAgent;
