import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useAxios from "../../hooks/useAxios";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";
import { useQueryClient } from "react-query";

export default function DeleteConfirm({ open, id, handleClose }) {
  const api = useAxios();
  const openAlert = useSetRecoilState(withAlert);
  const queryClient = useQueryClient();
  const deleteUser = async () => {
    const res = await api.delete(`/api/users/${id}`);
    openAlert({
      status: res.status,
      detail: "Sucessfully Deleted User!",
    });
    queryClient.invalidateQueries("users");
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" variant="h5">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancle</Button>
          <Button
            onClick={deleteUser}
            autoFocus
            sx={{ color: "secondary.red" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
