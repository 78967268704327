import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "@mui/material";
import DownloadDoneTwoToneIcon from "@mui/icons-material/DownloadDoneTwoTone";

const DatePickerValue = () => {
  const [startValue, setStartValue] = React.useState(dayjs("2024-04-17"));
  const [endValue, setEndValue] = React.useState(dayjs("2024-04-17"));
  const today = new Date();

  const handleDateSubmit = () => {
    console.log("start date: " + startValue);
    console.log("end date: " + endValue);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <DatePicker
            label="Start Date"
            value={startValue}
            onChange={(newValue) => setStartValue(newValue)}
            slotProps={{ field: { size: "small" } }}
          />
          <DatePicker
            label="End Date"
            value={endValue}
            onChange={(newValue) => setEndValue(newValue)}
            maxDate={dayjs(today)}
            slotProps={{ field: { size: "small" } }}
          />
          <Button variant="contained" onClick={handleDateSubmit} size="small">
            Submit &nbsp; <DownloadDoneTwoToneIcon />
          </Button>
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
};

export default DatePickerValue;
