import * as React from "react";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import Badge from "@mui/material/Badge";
import { Box } from "@mui/material";

const FailedTable = () => {
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterValues: [""],
  });

  const customToolbar = ({ showQuickFilter }) => {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbar showQuickFilter={showQuickFilter} />
      </GridToolbarContainer>
    );
  };

  const rows = [
    {
      id: 1,
      name: "Snow",
      account: 8553151614,
      amount: 78800000,
      status: "Failed",
      date: new Date(2023, 1, 1),
    },
    {
      id: 2,
      name: "Lannister",
      account: 8553151631,
      amount: 78800000,
      status: "Failed",
      date: new Date(2023, 1, 1),
    },
    {
      id: 3,
      name: "Lannister",
      account: 8553151631,
      amount: 78800000,
      status: "Failed",
      date: new Date(2023, 1, 1),
    },
    {
      id: 4,
      name: "Stark",
      account: 8553151611,
      amount: 78800000,
      status: "Failed",
      date: new Date(2023, 1, 1),
    },
    {
      id: 5,
      name: "Targaryen",
      account: 85531516455,
      amount: 78800000,
      status: "Failed",
      date: new Date(2023, 1, 1),
    },
    {
      id: 6,
      name: "Melisandre",
      account: 85531516150,
      amount: 78800000,
      status: "Failed",
      date: new Date(2023, 1, 1),
    },
    {
      id: 7,
      name: "Clifford",
      account: 8553151644,
      amount: 78800000,
      status: "Failed",
      date: new Date(2023, 1, 1),
    },
    {
      id: 8,
      name: "Frances",
      account: 8553151636,
      amount: 78800000,
      status: "Failed",
      date: new Date(2023, 1, 1),
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 80 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "account", headerName: "Account", width: 150 },
    { field: "amount", headerName: "Amount", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const color = "error";
        return <Badge badgeContent={params.value} color={color} />;
      },
    },
    { field: "date", headerName: "Date", width: 150, type: "date" },
  ];

  return (
    <Box sx={{ height: "100%", width: "100%", marginT: "1em" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        disableColumnFilter
        disableColumnSorting
        disableColumnSelector // 'Hide Column' and 'Manage Column'
        disableDensitySelector
        slots={{ toolbar: customToolbar }}
        slotProps={{ toolbar: { showQuickFilter: true } }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    </Box>
  );
};

export default FailedTable;
