import * as React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

const data = [
  { label: 'Total success amount', value: 4000, color: '#0088FE' },
  { label: 'Total fail amount', value: 3000, color: '#00C49F' },
];

const sizing = {
  margin: { right: 5 },
  width: 200,
  height: 200,
  legend: { hidden: true },
};
const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

const getArcLabel = (params) => {
  const percent = params.value / TOTAL;
  return `${(percent * 100).toFixed(0)}%`;
};

const PieChartComponent = () => {
  return (
    <Paper
      sx = {{
          width: "35%",
          display: "flex",
          flexDirection: "column",
          alignItems: 'center',
          justifyContent: "center",
        }}
        >
        <Typography sx={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 20px 0px 30px' }}>Daily Transfer Amount</Typography>
        <PieChart
        series={[
            {
            outerRadius: 80,
            data,
            arcLabel: getArcLabel,
            },
        ]}
        sx={{ width: "100%", 
            [`& .${pieArcLabelClasses.root}`]: {
            fill: 'white',
            fontSize: 14,
            },
        }}
        {...sizing}
        />
    </Paper>
  );
}

export default PieChartComponent;