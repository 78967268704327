import LineChartComponent from "../../components/LineChart";
import PieChartComponent from "../../components/PieChart";
import { Box } from "@mui/material";

const Dashboard = () => {
    return ( 
        <Box sx={{ 
            mb: "2rem",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginX: "1rem",
            gap: "1rem"
        }}>
            <LineChartComponent/>
            <PieChartComponent/>
        </Box>
     );
}
 
export default Dashboard;