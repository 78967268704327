import authAtom from "../recoil/auth/atom";
import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { role } from "../recoil/auth";

const PrivateRoute = ({ component: Component, roles }) => {
  const auth = useRecoilValue(authAtom);
  const userRole = useRecoilValue(role);

  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  if (roles && !roles.includes(userRole)) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Component />;
};

export default PrivateRoute;
