import * as React from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  // Filter,
} from "@mui/x-data-grid";
import Badge from "@mui/material/Badge";
import DatePickerValue from "../../components/Date";
// import { GridToolbarContainer } from "@mui/x-data-grid";
import StatusFilter from "../../components/Filter";
import Box from "@mui/material/Box";
import UploadFile from "../../components/Transfer/UploadFile";
import Button from "@mui/material/Button";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OngoingTable from "../../components/report/OngoingTable";
import SuccessTable from "../../components/report/SuccessTable";
import FailedTable from "../../components/report/FailedTable";
import DataAllTable from "../../components/report/DataAllTable";

const rows = [
  {
    id: 1,
    name: "Snow",
    account: 8553151614,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 2,
    name: "Lannister",
    account: 8553151631,
    amount: 78800000,
    status: "Pending",
    date: new Date(2023, 1, 1),
  },
  {
    id: 3,
    name: "Lannister",
    account: 8553151631,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 4,
    name: "Stark",
    account: 8553151611,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 5,
    name: "Targaryen",
    account: 85531516455,
    amount: 78800000,
    status: "Fail",
    date: new Date(2023, 1, 1),
  },
  {
    id: 6,
    name: "Melisandre",
    account: 85531516150,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 7,
    name: "Clifford",
    account: 8553151644,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 8,
    name: "Frances",
    account: 8553151636,
    amount: 78800000,
    status: "Fail",
    date: new Date(2023, 1, 1),
  },
  {
    id: 9,
    name: "Roxie",
    account: 8553151665,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 10,
    name: "Roxie",
    account: 8553151665,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 11,
    name: "Snow",
    account: 8553151614,
    amount: 78800000,
    status: "Fail",
    date: new Date(2023, 1, 1),
  },
  {
    id: 12,
    name: "Lannister",
    account: 8553151631,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 13,
    name: "Lannister",
    account: 8553151631,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 14,
    name: "Stark",
    account: 8553151611,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 15,
    name: "Targaryen",
    account: 85531516455,
    amount: 78800000,
    status: "Pending",
    date: new Date(2023, 1, 1),
  },
  {
    id: 16,
    name: "Melisandre",
    account: 85531516150,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 17,
    name: "Clifford",
    account: 8553151644,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
  {
    id: 18,
    name: "Frances",
    account: 8553151636,
    amount: 78800000,
    status: "Pending",
    date: new Date(2023, 1, 1),
  },
  {
    id: 19,
    name: "Roxie",
    account: 8553151665,
    amount: 78800000,
    status: "Success",
    date: new Date(2023, 1, 1),
  },
];

const columns = [
  { field: "id", headerName: "ID", width: 80 },
  { field: "name", headerName: "Name", width: 150 },
  { field: "account", headerName: "Account", width: 150 },
  { field: "amount", headerName: "Amount", width: 150 },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let color;
      if (params.value === "Success") {
        color = "success";
      } else if (params.value === "Fail") {
        color = "error";
      } else {
        color = "warning";
      }
      return <Badge badgeContent={params.value} color={color} />;
    },
  },
  { field: "date", headerName: "Date", width: 150, type: "date" },
];

const Report = () => {
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterValues: [""],
  });
  const [tab, setTab] = useState("1");

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  const customToolbar = ({ showQuickFilter }) => {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <StatusFilter />
        <GridToolbar showQuickFilter={showQuickFilter} />
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <Box>
        <DatePickerValue />
      </Box>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All" value="1" />
              <Tab label="Ongoing" value="2" />
              <Tab label="Successful" value="3" />
              <Tab label="Failed" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ color: "primary.dark" }}>
            <DataAllTable />
          </TabPanel>
          <TabPanel value="2" sx={{ color: "primary.dark" }}>
            <OngoingTable />
          </TabPanel>
          <TabPanel value="3" sx={{ color: "primary.dark" }}>
            <SuccessTable />
          </TabPanel>
          <TabPanel value="4" sx={{ color: "primary.dark" }}>
            <FailedTable />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default Report;
