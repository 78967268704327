import { Typography, Collapse, ButtonGroup, Box } from "@mui/material";
import UploadFile from "./UploadFile";
import Button from "@mui/material/Button";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DnsIcon from "@mui/icons-material/Dns";
import Divider from "@mui/material/Divider";
import AddRecord from "./AddRecord";

const Upload = () => {
  const [open, setOpen] = useState(false);
  const [openManualForm, setOpenManualForm] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const manualDialogOpen = () => {
    setOpen(!open);
    setOpenManualForm(!openManualForm);
  };
  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          width: "150px",
        }}
      >
        <Typography>Upload</Typography>
        {open ? (
          <ExpandLess sx={{ marginLeft: "auto" }} />
        ) : (
          <ExpandMore sx={{ marginLeft: "auto" }} />
        )}
      </Button>
      <Collapse
        sx={{
          overflow: "hidden",
          position: "absolute",
          zIndex: 100,
        }}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <ButtonGroup
          orientation="vertical"
          aria-label="Vertical button group"
          sx={{ width: "150px" }}
        >
          <UploadFile />
          <Divider />
          <Button variant="contained" onClick={manualDialogOpen}>
            <DnsIcon />
            <Typography>Manual</Typography>
          </Button>
        </ButtonGroup>
      </Collapse>

      {openManualForm && (
        <AddRecord
          open={openManualForm}
          handleClose={() => setOpenManualForm(!openManualForm)}
        />
      )}
    </Box>
  );
};

export default Upload;
