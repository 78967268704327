import * as React from "react";
import {
  DialogContent,
  useMediaQuery,
  Box,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import useAxios from "../../hooks/useAxios";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddRecord = ({ open, handleClose }) => {
  const initialData = {
    wallet_type: "",
    sender_name: "",
    receiver_name: "",
    receiver_nrc: "",
    receiver_num: "",
    amount: "",
  };
  const [data, setData] = useState(initialData);
  const api = useAxios();
  const queryClient = useQueryClient();

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]:
        e.target.name === "amount" ? Number(e.target.value) : e.target.value,
    });
  };

  const { isLoading, mutate } = useMutation(
    async () => {
      return await api.post("/api/archived/create", data);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("archived-list");
        handleClose();
      },
    }
  );

  const handleRecord = (e) => {
    e.preventDefault();
    mutate({ ...data });
  };

  const isSmallToMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Dialog
      open={open}
      fullScreen={isSmallToMediumScreen}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: { height: { md: 500 }, minWidth: { lg: 1000, md: 800 } },
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div">
            Add Record
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box component="form" onSubmit={handleRecord}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: 2, lg: 4 },
              marginBottom: "1rem",
            }}
          >
            <Box
              flex={0.5}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray" }}
              >
                Wallet Type
              </Typography>
              <TextField
                name="wallet_type"
                value={data.wallet_type}
                onChange={handleOnChange}
                size="small"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray" }}
              >
                Sender Name
              </Typography>
              <TextField
                name="sender_name"
                value={data.sender_name}
                onChange={handleOnChange}
                size="small"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray" }}
              >
                Receiver Name
              </Typography>
              <TextField
                name="receiver_name"
                value={data.receiver_name}
                onChange={handleOnChange}
                size="small"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />
            </Box>

            <Box
              flex={0.5}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray" }}
              >
                Receiver NRC
              </Typography>
              <TextField
                name="receiver_nrc"
                value={data.receiver_nrc}
                onChange={handleOnChange}
                size="small"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray" }}
              >
                Receiver Wallet Number
              </Typography>
              <TextField
                name="receiver_num"
                value={data.receiver_num}
                onChange={handleOnChange}
                size="small"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray" }}
              >
                Transfer Amount (MMK)
              </Typography>
              <TextField
                name="amount"
                value={data.amount}
                onChange={handleOnChange}
                size="small"
                type="number"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Button variant="outlined" onClick={handleClose}>
              Cancle
            </Button>
            <LoadingButton
              loading={isLoading}
              size="large"
              variant="contained"
              disableElevation
              type="submit"
            >
              Add
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddRecord;
