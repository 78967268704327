import { atom } from "recoil";

const role = atom({
  key: "access_role",
  default: localStorage.getItem("access_role")
    ? localStorage.getItem("access_role")
    : null,
});

export default role;
