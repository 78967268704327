import axios from "axios";
import authAtom from "../recoil/auth/atom";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { withAlert } from "../recoil/snackbar";
import { jwtDecode } from "jwt-decode";
import { role } from "../recoil/auth";

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const setAuth = useSetRecoilState(authAtom);
  const auth = useRecoilValue(authAtom);
  const openAlert = useSetRecoilState(withAlert);
  const accessRole = useSetRecoilState(role);
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const { isLoading, mutate } = useMutation(
    async () => {
      return await axios.post(
        `${process.env.REACT_APP_SERVICE_BASE_URL}/api/authentication/login`,
        data
      );
    },
    {
      onSuccess: (res) => {
        const decoded = jwtDecode(res.data.access_token);
        setAuth(res.data);
        localStorage.setItem("disbursement-auth", JSON.stringify(res.data));
        localStorage.setItem("access_role", decoded.role);
        navigate("/dashboard");
        accessRole(decoded.role);
      },
      onError: (error) => {
        openAlert({
          status: error.response.status,
          detail: error.response.data.message,
        });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  useEffect(() => {
    if (auth) {
      navigate("/dashboard");
    }
  }, [auth, navigate]);

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <LockOutlinedIcon />
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
        <TextField
          value={data.email}
          onChange={handleOnChange}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          value={data.password}
          onChange={handleOnChange}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <LoadingButton
          type="submit"
          loading={isLoading}
          fullWidth
          variant="contained"
        >
          Sign In
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Login;
