import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  'May 1',
  'May 2',
  'May 3',
  'May 4',
  'May 5',
  'May 6',
  'May 7',
];

export default function LineChartComponent() {
  return (
    <Paper
      sx = {{
        width: "60%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
    <Typography sx={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 20px 100px 30px' }}>Daily Transfer Count</Typography>
    <BarChart
      sx={{ width: "100%" }}
      height={300}
      series={[
        { data: pData, label: 'success', id: 'pvId' },
        { data: uData, label: 'fail', id: 'uvId' },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'band' }]}
    />
    </Paper>
  );
}
