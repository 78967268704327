import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Layout from "../Layout";
import Login from "../pages/Login";
import PrivateRoute from "../hocs/PrivateRoute";
import DashboardLayout from "../pages/Dashboard/DashboardLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import TransferLayout from "../pages/Transfer/TransferLayout";
import Transfer from "../pages/Transfer/Transfer";
import ManualTransfer from "../pages/Transfer/ManualTransfer";
import ReportLayout from "../pages/Report/ReportLayout";
import Report from "../pages/Report/Report";
import UserLayout from "../pages/User/UserLayout";
import Users from "../pages/User/Users";
import UserAdd from "../pages/User/UserAdd";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<PrivateRoute component={Layout} />}>
        <Route index element={<Navigate to="/dashboard" />} />
        <Route
          path="dashboard"
          element={
            <PrivateRoute
              component={DashboardLayout}
              roles={["Admin", "Supervisor", "Agent"]}
            />
          }
        >
          <Route index element={<Dashboard />} />
        </Route>
        <Route
          path="transfer"
          element={
            <PrivateRoute
              component={TransferLayout}
              roles={["Supervisor", "Agent"]}
            />
          }
        >
          <Route
            path="auto-transfer"
            element={
              <PrivateRoute component={Transfer} roles={["Supervisor"]} />
            }
          />
          <Route
            path="manual-transfer"
            element={
              <PrivateRoute
                component={ManualTransfer}
                roles={["Supervisor", "Agent"]}
              />
            }
          />
        </Route>
        <Route path="report" element={<ReportLayout />}>
          <Route index element={<Report />} />
        </Route>
        <Route
          path="user"
          element={<PrivateRoute component={UserLayout} roles={["Admin"]} />}
        >
          <Route index element={<Users />} />
          <Route path="add" element={<UserAdd />} />
        </Route>
      </Route>
    </>
  )
);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
