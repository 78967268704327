import * as React from "react";
import {
  DialogContent,
  useMediaQuery,
  Box,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RadioGroup from "@mui/material/RadioGroup";
import { LoadingButton } from "@mui/lab";
import Radio from "@mui/material/Radio";
import useAxios from "../../hooks/useAxios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditUser = ({ open, handleClose, id }) => {
  const initialData = {
    name: "",
    email: "",
    phone: "",
    is_active: "",
    is_suspend: "",
    role: "",
  };
  const api = useAxios();
  const queryClient = useQueryClient();
  const openAlert = useSetRecoilState(withAlert);
  const [data, setData] = useState(initialData);

  useQuery("user", async () => {
    const response = await api.get(`/api/users/${id}`);
    setData(response.data);
  });

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const { isLoading, mutate } = useMutation(
    async () => {
      return await api.put(`/api/users/${id}`, data);
    },
    {
      onSuccess: (res) => {
        if (res.status === 201) {
          setData(initialData);
          queryClient.invalidateQueries("users");
          handleClose();
          openAlert({
            status: res.status,
            detail: "Sucessfully Updated User!",
          });
        } else {
          openAlert({
            status: res.status,
            detail: res.data.message,
          });
          queryClient.invalidateQueries("user");
        }
      },
    }
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    mutate({ ...data });
  };

  const isSmallToMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Dialog
      open={open}
      fullScreen={isSmallToMediumScreen}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: { height: { md: 500 }, minWidth: { lg: 1000, md: 800 } },
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div">
            Edit User
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box component="form" onSubmit={handleUpdate}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: 2, lg: 4 },
              marginBottom: "1rem",
            }}
          >
            <Box
              flex={0.4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray" }}
              >
                Name
              </Typography>
              <TextField
                name="name"
                value={data.name}
                onChange={handleOnChange}
                size="small"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray" }}
              >
                Email
              </Typography>
              <TextField
                name="email"
                value={data.email}
                onChange={handleOnChange}
                size="small"
                type="email"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray" }}
              >
                Phone
              </Typography>
              <TextField
                name="phone"
                value={data.phone}
                onChange={handleOnChange}
                size="small"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />
            </Box>

            <Box flex={0.4} sx={{ ml: "2rem" }}>
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray" }}
              >
                Role
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="role"
                value={data.role}
                onChange={handleOnChange}
              >
                <FormControlLabel
                  sx={{ color: "gray" }}
                  value="Admin"
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel
                  sx={{ color: "gray" }}
                  value="Supervisor"
                  control={<Radio />}
                  label="Supervisor"
                />
                <FormControlLabel
                  sx={{ color: "gray" }}
                  value="Agent"
                  control={<Radio />}
                  label="Agent"
                />
              </RadioGroup>

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray", mt: 2 }}
              >
                Is Active
              </Typography>
              <FormControlLabel
                control={<Switch checked={Boolean(data.is_active)} />}
                onChange={handleOnChange}
                name="is_active"
              />

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: "gray", mt: 2 }}
              >
                Is Suspend
              </Typography>
              <FormControlLabel
                control={<Switch checked={Boolean(data.is_suspend)} />}
                onChange={handleOnChange}
                name="is_suspend"
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Button variant="outlined" onClick={handleClose}>
              Cancle
            </Button>
            <LoadingButton
              loading={isLoading}
              size="large"
              variant="contained"
              disableElevation
              type="submit"
            >
              Update
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditUser;
