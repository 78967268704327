import { Box, Typography } from "@mui/material";
import { useQuery } from "react-query";
import useAxios from "../../hooks/useAxios";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useState } from "react";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Badge from "@mui/material/Badge";
import Upload from "./Upload";
import AssignAgent from "./AssignAgent";
import { useCurrentAuth } from "../../utilities/useCurrentAuth";

const ArchivedDataList = () => {
  const api = useAxios();
  const gridApiRef = useGridApiRef();
  const auth = useCurrentAuth();
  const [rowId, setRowId] = useState([]);
  const [currentAssigned, setCurrentAssigned] = useState([]);

  const transfer = (id) => {
    console.log(id);
  };

  const transferAll = (ids) => {
    console.log(ids);
  };

  const handleRowsId = (ids) => {
    const realIds = ids.map((id) => {
      const row = gridApiRef.current.getRow(id);
      return row.id;
    });
    setRowId(realIds);
  };

  const handleAssigned = () => {
    gridApiRef.current.setRowSelectionModel([]);
  };

  const isRowSelectable = (params) => {
    if (auth.role === "Agent") {
      if (currentAssigned.length > 0) {
        return currentAssigned.includes(params.row.id);
      }
      // return params.row.status !== -1;
    }

    return true;
  };

  const columns = [
    { field: "displayId", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "account",
      headerName: "Account",
      width: 150,
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Transaction Status",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let color, value;
        if (params.value === -1) {
          color = "warning";
          value = "Ongoing";
        }
        return <Badge badgeContent={value} color={color} />;
      },
    },
    {
      field: "assign_to",
      headerName: "Assigned To",
      width: 250,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        <Typography
          sx={{
            fontWeight: "bold",
            color:
              params.value === "Not Assigned"
                ? "secondary.light"
                : "secondary.dark",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {params.value}
        </Typography>;
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 200,
      renderHeader: () => (
        <Button
          size="small"
          variant="contained"
          color="warning"
          onClick={() => transferAll(rowId)}
          disabled={rowId.length === 0}
        >
          Transfer &nbsp;
          <CurrencyExchangeIcon />
        </Button>
      ),
      getActions: (params) => [
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={() => transfer(params.id)}
          disabled={
            rowId.includes(params.id) ||
            (currentAssigned.length > 0 && !currentAssigned.includes(params.id))
          }
        >
          Transfer &nbsp;
          <CurrencyExchangeIcon />
        </Button>,
      ],
    },
  ];

  const { data } = useQuery("archived-list", async () => {
    const response = await api.get("api/archived");

    return response.data
      ? response.data.map((item, index) => ({
          id: item.id,
          displayId: index + 1,
          name: item.receiver_name,
          account: item.receiver_num,
          status: item.transaction_status,
          amount: item.amount,
          assign_to: item.assignAgent[0]?.user_assign_to.name ?? "Not Assigned",
        }))
      : [];
  });

  const { data: currentAssignedList } = useQuery(
    "current-assign-list",
    async () => {
      const response = await api.get(`api/assign-agent/${auth.id}`);

      if (response.status === 200) {
        const assignedIds = response.data?.map((item) => item.archived_id);
        if (auth.role === "Agent") {
          setCurrentAssigned(assignedIds);
        }
      }
    }
  );

  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Upload />
        <AssignAgent accountData={rowId} handleAssign={handleAssigned} />
      </Box>
      <Box sx={{ height: 650, width: "100%", mt: "1rem" }}>
        <DataGrid
          apiRef={gridApiRef}
          rows={data || []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          onRowSelectionModelChange={handleRowsId}
          pageSizeOptions={[10]}
          checkboxSelection
          disableRowSelectionOnClick
          isRowSelectable={isRowSelectable}
        />
      </Box>
    </>
  );
};

export default ArchivedDataList;
