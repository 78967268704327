import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Drawer,
  Paper,
  Typography,
  Collapse,
} from "@mui/material";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import { useRecoilValue, useSetRecoilState } from "recoil";
import authAtom from "./recoil/auth/atom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import NoteIcon from "@mui/icons-material/Note";
import {
  ExpandLess,
  ExpandMore,
  AutoFixHigh,
  Engineering,
} from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import { role } from "./recoil/auth";

const Layout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [state, setState] = useState(false);
  const setAuth = useSetRecoilState(authAtom);
  const setRole = useSetRecoilState(role);
  const userRole = useRecoilValue(role);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const newNavs = [
    {
      name: "Dashboard",
      route: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      name: "Transfer",
      route: "/transfer",
      icon: <CurrencyExchangeIcon />,
      subItems: [
        {
          name: "Auto Transfer",
          route: "/transfer/auto-transfer",
          icon: <CurrencyExchangeIcon />,
        },
        {
          name: "Manual Transfer",
          route: "/transfer/manual-transfer",
          icon: <CurrencyExchangeIcon />,
        },
      ],
    },
    {
      name: "Report",
      route: "/report",
      icon: <NoteIcon />,
    },
    {
      name: "User",
      route: "/user",
      icon: <GroupIcon />,
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("disbursement-auth");
    localStorage.removeItem("access_role");
    setAuth(null);
    setRole(null);
    navigate("/login");
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  return (
    <Box
      sx={{
        // background: "linear-gradient(to top, #fff0f3 50%, #EC008C 50%)",
        height: "100vh",
        position: "relative",
        zIndex: 0,
        px: { md: "1rem" },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        color: "#fff",
      }}
    >
      <Box sx={{ display: { md: "none" } }}>
        <Button onClick={toggleDrawer(true)}>
          <MenuIcon sx={{ color: "black" }} />
        </Button>
        <Drawer anchor="left" open={state} onClose={toggleDrawer(false)}>
          <Paper
            elevation={3}
            sx={{
              bgcolor: "#fff",
              p: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                mx: "auto",
                display: "block",
                cursor: "pointer",
                width: { xs: "3rem", md: "auto" },
              }}
              onClick={() => navigate("/")}
            />
            <Divider
              sx={{ mt: { md: "1rem" }, mb: { xs: "1rem", md: "2rem" } }}
            />
            <Link to="/dashboard">
              <Paper
                elevation={pathname.includes("/dashboard") ? 3 : 0}
                sx={{
                  px: "10px",
                  py: "10px",
                  mb: "1rem",
                  bgcolor: pathname.includes("/dashboard") && "#1565C0",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <DashboardIcon />
                </Box>
              </Paper>
            </Link>

            <Box
              sx={{
                display: userRole === "Admin" ? "none" : null,
                px: "10px",
                py: "10px",
                mb: "1rem",
                cursor: "pointer",
              }}
              onClick={handleClick}
            >
              <CurrencyExchangeIcon />
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Link
                style={{ display: userRole !== "Supervisor" ? "none" : null }}
                to="/transfer/auto-transfer"
              >
                <Paper
                  elevation={
                    pathname.includes("/transfer/auto-transfer") ? 3 : 0
                  }
                  sx={{
                    px: "10px",
                    py: "10px",
                    mb: "1rem",
                    bgcolor:
                      pathname.includes("/transfer/auto-transfer") && "#1565C0",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <AutoFixHigh />
                  </Box>
                </Paper>
              </Link>
              <Link to="/transfer/manual-transfer">
                <Paper
                  elevation={
                    pathname.includes("/transfer/manual-transfer") ? 3 : 0
                  }
                  sx={{
                    px: "10px",
                    py: "10px",
                    mb: "1rem",
                    bgcolor:
                      pathname.includes("/transfer/manual-transfer") &&
                      "#1565C0",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <Engineering />
                  </Box>
                </Paper>
              </Link>
            </Collapse>

            <Link to="/report">
              <Paper
                elevation={pathname.includes("/report") ? 3 : 0}
                sx={{
                  px: "10px",
                  py: "10px",
                  mb: "1rem",
                  bgcolor: pathname.includes("/report") && "#1565C0",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <NoteIcon />
                </Box>
              </Paper>
            </Link>

            <Link to="/user">
              <Paper
                elevation={pathname.includes("/user") ? 3 : 0}
                sx={{
                  px: "10px",
                  py: "10px",
                  mb: "1rem",
                  bgcolor: pathname.includes("/user") && "#1565C0",
                  display: userRole !== "Admin" ? "none" : null,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <GroupIcon />
                </Box>
              </Paper>
            </Link>

            <Box
              elevation={3}
              onClick={handleLogout}
              sx={{
                px: "10px",
                py: "10px",
                mt: "auto",
                cursor: "pointer",
                bgcolor: "#fff",
                width: "100%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <ExitToAppIcon sx={{ color: "primary.main" }} />
                <Typography
                  sx={{
                    color: "secondary.main",
                    textTransform: "none",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Logout
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Drawer>
      </Box>

      <Paper
        elevation={3}
        sx={{
          bgcolor: "#fff",
          width: "20%",
          px: "1rem",
          display: { md: "flex", xs: "none" },
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            mx: "auto",
            display: "block",
            cursor: "pointer",
            fontSize: "2rem",
            fontWeight: "bold",
            mt: "1rem",
          }}
          onClick={() => navigate("/")}
        >
          Business Pay
        </Box>
        <Divider sx={{ mt: { md: "1rem" }, mb: { xs: "1rem", md: "2rem" } }} />
        <Link
          style={{ textDecoration: "none", marginTop: "1rem" }}
          to="/dashboard"
        >
          <Paper
            elevation={pathname.includes("/dashboard") ? 3 : 0}
            sx={{
              px: "10px",
              py: "10px",
              bgcolor: pathname.includes("/dashboard") && "#1565C0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: "0.5rem",
                alignItems: "center",
              }}
            >
              <DashboardIcon />
              <Typography
                sx={{
                  color: pathname.includes("/dashboard") ? "#fff" : "black",
                  fontWeight: pathname.includes("/dashboard") ? "bold" : null,
                  userSelect: "none",
                  display: { xs: "none", md: "block" },
                }}
              >
                Dashboard
              </Typography>
            </Box>
          </Paper>
        </Link>

        <Box
          sx={{
            display: userRole === "Admin" ? "none" : "flex",
            columnGap: "0.5rem",
            alignItems: "center",
            marginTop: "1rem",
            cursor: "pointer",
            padding: "10px",
          }}
          onClick={handleClick}
        >
          <CurrencyExchangeIcon />
          <Typography>Transfer</Typography>
          {open ? (
            <ExpandLess sx={{ marginLeft: "auto" }} />
          ) : (
            <ExpandMore sx={{ marginLeft: "auto" }} />
          )}
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Link
            style={{ textDecoration: "none", display: userRole !== "Supervisor" ? "none" : null }}
            to="/transfer/auto-transfer"
          >
            <Paper
              elevation={pathname.includes("/transfer/auto-transfer") ? 3 : 0}
              sx={{
                px: "10px",
                py: "10px",
                bgcolor:
                  pathname.includes("/transfer/auto-transfer") && "#1565C0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: "0.5rem",
                  alignItems: "center",
                  marginLeft: "1rem",
                }}
              >
                <AutoFixHigh />
                <Typography
                  sx={{
                    color: pathname.includes("/transfer/auto-transfer")
                      ? "#fff"
                      : "black",
                    fontWeight: pathname.includes("/transfer/auto-transfer")
                      ? "bold"
                      : null,
                    userSelect: "none",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Auto Transfer
                </Typography>
              </Box>
            </Paper>
          </Link>

          <Link
            style={{ textDecoration: "none" }}
            to="/transfer/manual-transfer"
          >
            <Paper
              elevation={pathname.includes("/transfer/manual-transfer") ? 3 : 0}
              sx={{
                px: "10px",
                py: "10px",
                bgcolor:
                  pathname.includes("/transfer/manual-transfer") && "#1565C0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: "0.5rem",
                  alignItems: "center",
                  marginLeft: "1rem",
                }}
              >
                <Engineering />
                <Typography
                  sx={{
                    color: pathname.includes("/transfer/manual-transfer")
                      ? "#fff"
                      : "black",
                    fontWeight: pathname.includes("/transfer/manual-transfer")
                      ? "bold"
                      : null,
                    userSelect: "none",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Manual Transfer
                </Typography>
              </Box>
            </Paper>
          </Link>
        </Collapse>

        <Link
          style={{ textDecoration: "none", marginTop: "1rem" }}
          to="/report"
        >
          <Paper
            elevation={pathname.includes("/report") ? 3 : 0}
            sx={{
              px: "10px",
              py: "10px",
              bgcolor: pathname.includes("/report") && "#1565C0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: "0.5rem",
                alignItems: "center",
              }}
            >
              <NoteIcon />
              <Typography
                sx={{
                  color: pathname.includes("/report") ? "#fff" : "black",
                  fontWeight: pathname.includes("/report") ? "bold" : null,
                  userSelect: "none",
                  display: { xs: "none", md: "block" },
                }}
              >
                Report
              </Typography>
            </Box>
          </Paper>
        </Link>

        <Link
          style={{
            textDecoration: "none",
            marginTop: "1rem",
            display: userRole !== "Admin" ? "none" : null,
          }}
          to="/user"
        >
          <Paper
            elevation={pathname.includes("/user") ? 3 : 0}
            sx={{
              px: "10px",
              py: "10px",
              bgcolor: pathname.includes("/user") && "#1565C0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: "0.5rem",
                alignItems: "center",
              }}
            >
              <GroupIcon />
              <Typography
                sx={{
                  color: pathname.includes("/user") ? "#fff" : "black",
                  fontWeight: pathname.includes("/user") ? "bold" : null,
                  userSelect: "none",
                  display: { xs: "none", md: "block" },
                }}
              >
                Users
              </Typography>
            </Box>
          </Paper>
        </Link>

        <Box
          elevation={3}
          onClick={handleLogout}
          sx={{
            px: "10px",
            py: "10px",
            mt: "auto",
            cursor: "pointer",
            bgcolor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: "0.5rem",
              alignItems: "center",
            }}
          >
            <ExitToAppIcon />
            <Typography
              sx={{
                color: "black",
                textTransform: "none",
                display: { xs: "none", md: "block" },
              }}
            >
              Logout
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Box
        sx={{
          px: "1rem",
          width: "100%",
          minWidth: 0,
          display: "flex",
          flexDirection: "column",
          py: { xs: "1rem", md: "1rem" },
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "1rem",
          }}
        >
          <Box>
            {newNavs.map(
              (nav, index) =>
                pathname.includes(nav.route) && (
                  <Box key={index}>
                    <Breadcrumbs
                      sx={{ color: "primary", fontWeight: "bold" }}
                      aria-label="breadcrumb"
                    >
                      <Link href="/">
                        <HomeIcon
                          sx={{ color: "primary", fontWeight: "bold" }}
                        />
                      </Link>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {nav.name}
                      </Typography>
                    </Breadcrumbs>
                  </Box>
                )
            )}
          </Box>
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
