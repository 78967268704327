import { Box } from "@mui/material";
import ArchivedDataList from "../../components/Transfer/ArchivedDataList";

const Transfer = () => {
  return (
    <>
      <Box sx={{ mt: "1rem" }}>
        <ArchivedDataList />
      </Box>
    </>
  );
};

export default Transfer;
