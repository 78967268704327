import { useRecoilValue } from "recoil";
import authAtom from "../recoil/auth";

function base64UrlDecode(encoded) {
  let decoded = encoded.replace(/-/g, "+").replace(/_/g, "/");
  while (decoded.length % 4) {
    decoded += "=";
  }
  return decodeURIComponent(
    atob(decoded)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

function extractJwtPayload(jwt) {
  const parts = jwt.split(".");
  if (parts.length !== 3) throw new Error("Invalid JWT token");

  const payload = parts[1];
  return JSON.parse(base64UrlDecode(payload));
}

export const useCurrentAuth = () => {
  const auth = useRecoilValue(authAtom);
  const result = extractJwtPayload(auth.access_token);
  const data = {
    id: result.sub,
    role: result.role,
  };

  return data;
};
