import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    // use for typo color and line
    primary: {
      main: "#0D519D",
      hover: "#03045e",
      light: "#eee",
      dark: "#5C5C5C"
    },
    // use for mui components like Button and input
    secondary: {
      main: "#EC008C",
      light: "#A7A7A7",
      dark: "#5C5C5C",
      yellow: "#a6a805",
      red: "#de0735",
    },
  },
  typography: {},
});
