import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { LoadingButton } from "@mui/lab";
import { useQueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";

const UserAdd = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const api = useAxios();
  const openAlert = useSetRecoilState(withAlert);

  const initialData = {
    name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    role: "Agent",
  };

  const [data, setData] = useState(initialData);

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const { isLoading, mutate } = useMutation(
    async () => {
      return await api.post("/api/users/create", data);
    },
    {
      onSuccess: (res) => {
        if (res.status === 201) {
          setData(initialData);
          queryClient.invalidateQueries("users");
          navigate("/user");
          openAlert({
            status: res.status,
            detail: "Sucessfully Created User!",
          });
        } else {
          openAlert({
            status: res.status,
            detail: res.data.message,
          });
        }
      },
    }
  );

  const handleCreate = (e) => {
    e.preventDefault();

    if (data.password !== data.confirm_password) {
      openAlert({
        status: 400,
        detail: "Passwords do not match.",
      });
      return;
    }

    mutate({ ...data });
  };
  return (
    <Box>
      <Typography
        variant="h5"
        sx={{ color: "gray", fontWeight: "bold", marginBottom: "2rem" }}
      >
        Add User
      </Typography>

      <Box component="form" onSubmit={handleCreate}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: { xs: 2, lg: 4 },
            marginBottom: "1rem",
          }}
        >
          <Box
            flex={0.4}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              sx={{ color: "gray" }}
            >
              Name
            </Typography>
            <TextField
              name="name"
              value={data.name}
              onChange={handleOnChange}
              size="small"
              sx={{
                border: "none",
                borderRadius: 1,
                "& .MuiInputBase-root": {
                  backgroundColor: "#F0F0F0",
                  borderRadius: 1,
                  outline: "none",
                  "&:hover": {
                    backgroundColor: "#F0F0F0",
                  },
                },
              }}
            />

            <Typography
              variant="subtitle2"
              fontWeight="bold"
              sx={{ color: "gray" }}
            >
              Email
            </Typography>
            <TextField
              name="email"
              value={data.email}
              onChange={handleOnChange}
              size="small"
              type="email"
              sx={{
                border: "none",
                borderRadius: 1,
                "& .MuiInputBase-root": {
                  backgroundColor: "#F0F0F0",
                  borderRadius: 1,
                  outline: "none",
                  "&:hover": {
                    backgroundColor: "#F0F0F0",
                  },
                },
              }}
            />

            <Typography
              variant="subtitle2"
              fontWeight="bold"
              sx={{ color: "gray" }}
            >
              Phone
            </Typography>
            <TextField
              name="phone"
              value={data.phone}
              onChange={handleOnChange}
              size="small"
              sx={{
                border: "none",
                borderRadius: 1,
                "& .MuiInputBase-root": {
                  backgroundColor: "#F0F0F0",
                  borderRadius: 1,
                  outline: "none",
                  "&:hover": {
                    backgroundColor: "#F0F0F0",
                  },
                },
              }}
            />

            <Typography
              variant="subtitle2"
              fontWeight="bold"
              sx={{
                color: "gray",
              }}
            >
              Password
            </Typography>
            <TextField
              name="password"
              value={data.password}
              onChange={handleOnChange}
              size="small"
              type="password"
              sx={{
                border: "none",
                borderRadius: 1,
                "& .MuiInputBase-root": {
                  backgroundColor: "#F0F0F0",
                  borderRadius: 1,
                  outline: "none",
                  "&:hover": {
                    backgroundColor: "#F0F0F0",
                  },
                },
              }}
            />

            <Typography
              variant="subtitle2"
              fontWeight="bold"
              sx={{
                color: "gray",
              }}
            >
              Confirm Password
            </Typography>
            <TextField
              name="confirm_password"
              value={data.confirm_password}
              onChange={handleOnChange}
              size="small"
              type="password"
              sx={{
                border: "none",
                borderRadius: 1,
                "& .MuiInputBase-root": {
                  backgroundColor: "#F0F0F0",
                  borderRadius: 1,
                  outline: "none",
                  "&:hover": {
                    backgroundColor: "#F0F0F0",
                  },
                },
              }}
            />
          </Box>

          <Box flex={0.4}>
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              sx={{ color: "gray" }}
            >
              Role
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="role"
              value={data.role}
              onChange={handleOnChange}
            >
              <FormControlLabel
                sx={{ color: "gray" }}
                value="Admin"
                control={<Radio />}
                label="Admin"
              />
              <FormControlLabel
                sx={{ color: "gray" }}
                value="Supervisor"
                control={<Radio />}
                label="Supervisor"
              />
              <FormControlLabel
                sx={{ color: "gray" }}
                value="Agent"
                control={<Radio />}
                label="Agent"
              />
            </RadioGroup>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Cancle
          </Button>
          <LoadingButton
            loading={isLoading}
            size="large"
            variant="contained"
            disableElevation
            type="submit"
          >
            Create
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default UserAdd;
